<template>
  <v-container class="pa-0">
    <div
      v-if="!isVestingAdded"
      class="primary--text text-body-1 font-weight-light cursor-pointer font-weight-medium"
      @click="toggleVestingSchedule"
    >
      <v-icon class="mb-1 primary--text">
        mdi-plus
      </v-icon>
      Add vesting schedule
    </div>
    <div
      v-if="isVestingAdded"
      class="primary--text text-body-1 font-weight-light cursor-pointer font-weight-medium"
      @click="toggleVestingSchedule"
    >
      <v-icon class="mb-1 primary--text">
        mdi-minus
      </v-icon>
      Remove vesting schedule
    </div>
    <div v-if="isVestingAdded">
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Vesting schedule template'"
          >
            <common-input-select
              v-model="selectedVestingId"
              label="Vesting Schedule Template (Optional)"
              placeholder="Select vesting schedule template"
              :items="vestingList"
              item-text="vesting_schedule.name"
              item-value="id"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              clearable
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Vesting start date'"
            :rules="{ required: true }"
          >
            <input-template-date
              v-model="vestingStartedOn"
              :mask="'##/##/####'"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              :input-templ="{label: 'Vesting start date',mandatory: true}"
              clearable
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-0">
          <vesting-schedule-form
            v-model="vestingSchedule"
            :hide-name="true"
            :is-edit="isEdit"
            @input="updatevestingSchedule"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
  import moment from 'moment'
  import VestingScheduleForm from './VestingScheduleForm.vue'
  import * as captableService from '@/components/common/captable/services/captable'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'

  export default {
    name: 'CommonVestingForm',

    components: {
      VestingScheduleForm,
      InputTemplateDate,
    },

    props: {
      value: {
        type: Object,
        default: () => {},
      },
      isEdit: {
        type: Boolean,
      },
    },

    data: () => ({
      isVestingAdded: false,
      vestingList: [],
      selectedVestingId: '',
      data: {},
      vestingStartedOn: '',
      vestingSchedule: {},
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      formData: {
        get () {
          return this.value || {}
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      vestingStartedOn: {
        handler (val) {
          if (val) {
            this.formData.vesting_started_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      selectedVestingId: {
        handler (val) {
          if (val) {
            this.updateSelectedVesting(val)
          }
        },
        immediate: true,
      },
      value: {
        handler (val) {
          if (val) {
            this.vestingStartedOn = val.vesting_started_on ? moment(val.vesting_started_on).format('MM/DD/YYYY') : ''
            this.vestingSchedule = val.vesting_schedule || {}
            if (val.vesting_schedule && ((val.vesting_schedule.time_based && Object.keys(val.vesting_schedule.time_based).length > 0) || (val.vesting_schedule.custom && (Object.keys(val.vesting_schedule.custom).length > 0)))) {
              this.isVestingAdded = true
            }
          }
        },
        immediate: true,
      },
    },

    mounted () {
      if (!this.vestingList || this.vestingList.length === 0) {
        this.loadVestingList()
      }
    },

    methods: {
      toggleVestingSchedule () {
        this.isVestingAdded = !this.isVestingAdded
        if (!this.isVestingAdded) {
          delete this.formData.vesting_started_on
          delete this.formData.vesting_schedule
          this.$emit('update', this.formData)
        }
      },
      updateSelectedVesting (val) {
        const selectedVesting = this.vestingList.find((item) => { return item.id === val })
        this.vestingSchedule = JSON.parse(JSON.stringify(selectedVesting.vesting_schedule))
        this.updatevestingSchedule()
      },
      async loadVestingList () {
        try {
          const resp = await captableService.getVesingScheduleList(this.captableId)
          if (resp && resp.data) {
            this.vestingList = resp.data.vesting_schedules
          }
        } catch (e) {}
      },
      updatevestingSchedule () {
        this.formData.vesting_schedule = this.vestingSchedule
      },
    },
  }
</script>
